
import Vue from "vue";
import { tableColumns } from "./listViewColumns";
import { legacyTableColumns } from "./listViewLegacyColumns";
import * as selectOptions from "@/helpers/selectOptions";
import Charts from "@/components/Charts/Charts.vue";
import {
  quoteMapActions,
  quoteMapGetters,
  quoteMapState
} from "@/store/modules/quote";
import NoResultsFound from "@/components/NotFound/NoResultsFound.vue";
import { downloadQuoteDocument } from "@/helpers/downloadLossForm";
import {
  companiesMapActions,
  companiesMapGetters
} from "@/store/modules/companies";
import { ICompany } from "@/store/modules/companies/types";
import { authMapGetters } from "@/store/modules/auth";
import { get } from "lodash";
import { excludedCompanies } from "@/helpers/companies";
import { clearSelectionsNotInData, getSystemFilesURL } from "@/helpers";
interface IQuotesData {
  tableActions: any[];
  tableColumns: any;
  legacyTableColumns: any;
  errorMessage: string;
  isLoading: boolean;
  isAdmin: boolean;
  loading: boolean;
  successMessage: string;
  companyId: string;
  customBulkMenuActions: any[];
  customBulkActionsMenuTitle: string;
  selectedDocuments: string[];
  showQuoteChart: string;
  queryOverride: any;
  quoteId: string;
  queryForChart: any;
  queryForPropertyAnalytics: any;
  showLegacy: boolean;
  key: number;
  loadingText: string;
  quoteType: string;
  defaultQuery: {
    __limit: number;
    __page: number;
    __offset: number;
  };
  reserveSelection: boolean;
}
export default Vue.extend({
  name: "quotes",
  components: { Charts, NoResultsFound },
  props: ["preAction"],
  data(): IQuotesData {
    return {
      successMessage: "",
      errorMessage: "",
      isLoading: false,
      isAdmin: true,
      tableActions: [],
      tableColumns: tableColumns,
      legacyTableColumns: legacyTableColumns,
      loading: false,
      companyId: "",
      customBulkMenuActions: [
        { title: "Draft", disabled: false },
        { title: "Not Submitted", disabled: false },
        { title: "Submitted", disabled: false },
        { title: "Received", disabled: false },
        { title: "Rejected", disabled: false }
      ],
      customBulkActionsMenuTitle: "Change Status to:",
      selectedDocuments: [],
      showQuoteChart: "",
      queryForChart: {},
      queryOverride: { deleted: false, isLegacy: false },
      quoteId: "",
      queryForPropertyAnalytics: {},
      defaultQuery: {
        __limit: 50,
        __page: 1,
        __offset: 0
      },
      showLegacy: false,
      key: 0,
      loadingText: "",
      quoteType: "Quotes",
      reserveSelection: true
    };
  },
  methods: {
    ...quoteMapActions([
      "getQuotes",
      "deleteQuote",
      "updateQuote",
      "deleteBulkQuotes",
      "restoreBulkQuotes",
      "changeQuoteStatus"
    ]),
    ...companiesMapActions(["getCompanies"]),
    async bulkActionHandler(action: string) {
      switch (action) {
        case "Print Selected": {
          try {
            this.loadingText = "Printing selected quotes. Please wait...";
            this.loading = true;
            let message =
              "Only quotes that have been Submitted will be printed";
            let messageType = "info";
            let loadingString = "onlyPrintSubmitted";
            const submittedQuotesIds: any = this.selectedDocuments
              .filter(function(quote: any) {
                return (
                  quote.status === "submitted" ||
                  quote.status === "received" ||
                  quote.status === "rejected"
                );
              })
              .map((quote: any) => quote.id);
            if (submittedQuotesIds.length == 0) {
              message =
                "Quotes selected are not submitted and therefore can't be printed";
              messageType = "error";
              loadingString = "quotesNotSubmitted";
            }
            this.$appNotify({
              title: "Please note:",
              type: messageType,
              message: message,
              offset: 100,
              position: "top-right"
            });
            await downloadQuoteDocument(
              {
                quoteIds: submittedQuotesIds
              },
              loadingString
            );
          } catch (error) {
            this.$appNotifyError(error.message);
          } finally {
            this.loading = false;
            this.loadingText = "";
          }
          break;
        }
        case "Delete Selected": {
          try {
            this.loadingText = "Deleting selected quotes. Please wait...";
            this.loading = true;
            const _ids: string[] = this.selectedDocuments.map(
              (doc: any) => doc._id
            );
            await this.deleteBulkQuotes(_ids);
            this.$appNotifySuccess("Selected Quotes Deleted");
            this.fetchPageData(
              this.$store.state.list_view_module["filterQuery"] ||
                this.defaultQuery
            );
          } catch (error) {
            this.$appNotifyError(error.message);
          } finally {
            this.loading = false;
            this.loadingText = "";
          }
          break;
        }
        case "Restore Selected": {
          try {
            this.loadingText = "Restoring selected quotes. Please wait...";
            this.loading = true;
            const _ids: string[] = this.selectedDocuments.map(
              (doc: any) => doc._id
            );
            await this.restoreBulkQuotes(_ids);
            this.$appNotifySuccess("Selected Quotes Restored");
            this.fetchPageData(
              this.$store.state.list_view_module["filterQuery"] ||
                this.defaultQuery
            );
          } catch (error) {
            this.$appNotifyError(error.message);
          } finally {
            this.loading = false;
            this.loadingText = "";
          }
          break;
        }
        default:
          break;
      }
    },
    async onToolbarItemClick(event: string) {
      switch (event) {
        case "create":
          this.$router.push(`/quotes/create-draft`).catch(() => {});
          break;
        case "quote-counter":
        case "quote-county":
        case "quote-status":
        case "quote-agents":
        case "quote-agency":
        case "quote-daily-report-total":
        case "quote-ratings-report":
        case "quote-daily-report-single":
          this.quoteType = "Chart";
          await this.$router.replace({ query: {} });
          this.queryForChart = {};
          this.showQuoteChart = event;
          break;
        case "quote-report-generation":
          this.quoteType = "Quote report";
          await this.$router.replace({ query: {} });
          this.queryForChart = {};
          this.showQuoteChart = event;
          break;
        case "quote-property-analytics":
          this.quoteType = "Property analytics";
          await this.$router.replace({
            path: "/quotesPropertyAnalytics/list",
            query: {}
          });
          this.showQuoteChart = event;
          break;
        case "quote":
          this.quoteType = "Quotes";
          await this.$router.replace({ query: {} });
          this.showQuoteChart = "";
          this.queryOverride.deleted = false;
          this.queryOverride.isLegacy = false;
          if (typeof this.$route.query.__columns == "string") {
            this.$route.query.__columns = this.$route.query.__columns
              .toString()
              .replace("companyNumbers", "ratingSelectedData.companyNumber");
          }
          this.showLegacy = false;
          this.key++;
          break;
        case "quotes-deleted":
          this.quoteType = "Trash Bin";
          this.queryOverride = {
            ...this.queryOverride,
            deleted: true,
            isLegacy: false,
            status__in: []
          };
          this.fetchPageData(this.defaultQuery);
          break;
        case "quotes-legacy":
          this.quoteType = "Legacy quotes";
          this.queryOverride = {
            ...this.queryOverride,
            isLegacy: true,
            deleted: false
          };
          if (typeof this.$route.query.__columns == "string") {
            this.$route.query.__columns = this.$route.query.__columns
              .toString()
              .replace("ratingSelectedData.companyNumber", "companyNumbers");
          }
          this.showLegacy = true;
          this.key++; //forces listview to re-render, so data gets fetched again
          break;
        case "quote-bulk-activities":
          this.$router.push(`/admin/quotes/activities`).catch(() => {});
          break;
        default:
          this.quoteType = "";
          break;
      }
    },
    async customBulkActionHandler(statusTitle: string) {
      this.reserveSelection = false;
      const message = `Are you sure you want to change this quote to ${statusTitle}?`;
      await this.$confirm(`${message}`, "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
        closeOnClickModal: false,
        showClose: false,
        cancelButtonClass:
          "el-button rounded-none ml-0 mr-0 el-button--primary el-button--medium is-plain"
      });

      const statusValues: any = {
        Received: "received",
        Rejected: "rejected",
        Submitted: "submitted",
        Draft: "draft",
        "Rating In Progress": "rating-in-progress",
        "Rating Completed": "rating-completed",
        "Rating Selected": "rating-selected",
        "Not Submitted": "not-submitted"
      };
      const status = statusValues[statusTitle];
      const _ids: any = this.selectedDocuments.map((quote: any) => quote.id);
      const length = this.selectedDocuments.length;
      try {
        this.loadingLogic();
        const changeStatusRes = await this.changeQuoteStatus({ _ids, status });
        const { success } = changeStatusRes;
        if (success) {
          this.fetchPageData({
            __limit: 50,
            __page: 1,
            __offset: 0
          }).then(() => this.$appNotifySuccess("Status Changed"));
        }
      } catch (error) {
        this.$bugSnagClient.notify(error);
        this.$appNotifyError(
          error.message ||
            `Couldn't change quote ${length > 1 ? "statuses" : "status"}  `
        );
      } finally {
        this.loadingLogic(false);
      }
    },
    loadingLogic(start = true) {
      this.loading = start;
      this.loadingText = start ? "Loading Quotes. Please wait..." : "";
      if (start) {
        this.$emit("scrollTop");
      }
    },
    async fetchPageData(queryObject: any): Promise<any> {
      try {
        const payload = {
          query: { ...queryObject, ...this.queryOverride }
        };
        this.loadingLogic();
        await this.getQuotes(payload);
        this.selectedDocuments = clearSelectionsNotInData(
          this.quotes,
          this.selectedDocuments
        );
      } catch (error) {
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
        this.loadingText = "";
        this.reserveSelection = true;
      }
    },
    async viewCallback(dataReceived: any): Promise<any> {
      const {
        _id,
        ratingValidations,
        ratings,
        status,
        isLegacy,
        onBehalfOfEmail = "",
        onBehalfOfAgentCode = "",
        distanceToCoast = 0
      } = dataReceived;
      const {
        hasValidLossInformation,
        hasCreditRating,
        hasSelectedRating,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        hasTerritoryInformation,
        hasRiskAddress
      } = ratingValidations;
      // redirect to view page if status is submitted
      if ((status === "submitted" || status === "received") && !isLegacy) {
        return this.$router.push(`/quotes/${_id}/view`);
      }

      if (isLegacy) {
        return this.$router.push(`/quotes/${_id}/view-legacy`);
      }

      if (onBehalfOfEmail && onBehalfOfAgentCode && !distanceToCoast) {
        return this.$router.push(`/quotes/${_id}/risk-address`);
      }
      //send the person to the latest screen by default.
      //they may just navigate wherever they want to
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      // const ratingStatus = ["rating-in-progress", "rating-completed"];
      if (
        hasValidLossInformation &&
        hasCreditRating &&
        this.$getCurrentUser.role != "admin"
      ) {
        return this.$router.push(`/quotes/${_id}/remarks-and-mortgages`);
      } else if (hasCreditRating && this.$getCurrentUser.role != "admin") {
        return this.$router.push(`/quotes/${_id}/loss-report`);
      } else if (hasSelectedRating || ratings.length > 0) {
        return this.$router.push(`/quotes/${_id}/property-rating`);
      } else if (hasRiskAddress) {
        return this.$router.push(`/quotes/${_id}/territory`);
      } else {
        this.$router.push(`/quotes/${_id}`);
      }
      // if (hasCreditRating) {
      //   return this.$router.push(`/quotes/${_id}/loss-report`);
      // }
      // if (hasSelectedRating) {
      //   return this.$router.push(`/quotes/${_id}/rating-application`);
      // }

      // if (ratings.length > 0 || ratingStatus.includes(status)) {
      //   return this.$router.push(`/quotes/${_id}/property-ratings`);
      // }
      // if (hasTerritoryInformation) {
      //   return this.$router.push(`/quotes/${_id}/rating-application`);
      // }
      // if (hasRiskAddress) {
      //   return this.$router.push(`/quotes/${_id}/territory`);
      // }
    },
    async printQuote(data: any) {
      if (!data.ratingSelected) {
        this.$appNotifyError(
          "Quote cannot be printed because no rate has been selected."
        );
        return;
      }
      try {
        this.loading = true;
        this.loadingText = "Printing Quote. Please wait...";
        const printFor =
          data.status &&
          (data.status.toLowerCase() == "submitted" ||
            data.status.toLowerCase() == "received" ||
            data.status.toLowerCase() == "rejected" ||
            data.status.toLowerCase() === "rating-selected")
            ? "application"
            : "rate";
        await downloadQuoteDocument({
          quoteIds: [data._id],
          rateId: data.ratingSelected,
          printFor
        });
      } catch (error) {
        this.errorMessage = (error as any).message;
      } finally {
        this.loading = false;
        this.loadingText = "";
      }
    },
    async handleResponseAction(payload: any) {
      const { data } = payload;
      switch (payload.name) {
        case "delete":
          this.deleteCallback(data._id);
          break;
        case "view":
        case "edit":
          this.viewCallback(data);
          break;
        case "print":
          this.printQuote(data);
          break;
        case "restore":
          this.restoreQuote(data._id);
          break;
        case "activities":
          this.$router.push(`/quotes/${data._id}/activities`).catch(() => {});
          break;
        default:
          this.$message({
            type: "error",
            message: "Operation specified is not supported"
          });
      }
    },
    async restoreQuote(id: string): Promise<any> {
      try {
        this.loading = true;
        this.loadingText = "Restoring quote. Please wait...";
        await this.updateQuote({ id: id, update: { deleted: false } });
        this.successMessage = "Quote Successfully Restored";
        this.fetchPageData(this.defaultQuery);
      } catch (e) {
        this.errorMessage = e.message;
      } finally {
        this.loading = false;
        this.loadingText = "";
      }
    },
    async deleteOneQuote(): Promise<void> {
      try {
        this.loading = true;
        this.loadingText = "Deleting quote. Please wait...";
        await this.deleteQuote(this.quoteId);
        this.$modal.hide("quotesDeleteModal");
        this.successMessage = "Quote Successfully Deleted";
      } catch (e) {
        this.errorMessage = e.message;
      } finally {
        this.loading = false;
        this.loadingText = "";
      }
    },
    async deleteCallback(id: any): Promise<void> {
      try {
        this.quoteId = id;
        this.$modal.show("quotesDeleteModal");
      } catch (error) {
        // delete disabled
      }
    },
    reportGenerationQueryFetch(query: any) {
      const {
        companyNumbers__in,
        policyType__in,
        status__in,
        createdOn__gte,
        createdOn__lte,
        dateSubmitted__gte,
        dateSubmitted__lte,
        modifiedOn__gte,
        modifiedOn__lte,
        isLegacy__in
      } = query;
      const queryObject: any = {};

      if (companyNumbers__in) {
        const companyNumberData =
          typeof companyNumbers__in === "number"
            ? [companyNumbers__in]
            : companyNumbers__in.split(",").map((num: string) => parseInt(num));
        queryObject["companyNumbers"] = {
          $in: companyNumberData
        };
      }
      if (policyType__in) {
        queryObject["policyType"] = { $in: policyType__in.split(",") };
      }
      if (status__in) {
        queryObject["status"] = { $in: status__in.split(",") };
      }
      if (createdOn__gte || createdOn__lte) {
        queryObject["createdOn"] = {};
        if (createdOn__gte) {
          queryObject["createdOn"]["$gte"] = new Date(createdOn__gte);
        }

        if (createdOn__lte) {
          queryObject["createdOn"]["$lte"] = new Date(createdOn__lte);
        }
      }
      if (dateSubmitted__gte || dateSubmitted__lte) {
        queryObject["dateSubmitted"] = {};
        if (dateSubmitted__gte) {
          queryObject["dateSubmitted"]["$gte"] = new Date(dateSubmitted__gte);
        }

        if (dateSubmitted__lte) {
          queryObject["dateSubmitted"]["$lte"] = new Date(dateSubmitted__lte);
        }
      }
      if (modifiedOn__gte || modifiedOn__lte) {
        queryObject["modifiedOn"] = {};
        if (modifiedOn__gte) {
          queryObject["modifiedOn"]["$gte"] = new Date(modifiedOn__gte);
        }

        if (modifiedOn__lte) {
          queryObject["modifiedOn"]["$lte"] = new Date(modifiedOn__lte);
        }
      }
      if (isLegacy__in) {
        const data = isLegacy__in
          .split(",")
          .map((val: string) => (val === "no" ? false : true));
        queryObject["isLegacy"] = { $in: data };
      }
      this.queryForChart = queryObject;
    },
    isLegacyQueryFetch(query: any) {
      const {
        companyNumbers__in,
        isLegacy__in,
        createdOn__gte,
        createdOn__lte
      } = query;
      const queryObject: any = {};

      if (isLegacy__in) {
        const data = isLegacy__in
          .split(",")
          .map((val: string) => (val === "no" ? false : true));
        queryObject["isLegacy"] = { $in: data };
      }
      if (companyNumbers__in) {
        const companyNumberData =
          typeof companyNumbers__in === "number"
            ? [companyNumbers__in]
            : companyNumbers__in.split(",").map((num: string) => parseInt(num));
        queryObject["companyNumbers"] = {
          $in: companyNumberData
        };
      }
      if (createdOn__gte || createdOn__lte) {
        queryObject["createdOn"] = {};
        if (createdOn__gte) {
          queryObject["createdOn"]["$gte"] = new Date(createdOn__gte);
        }

        if (createdOn__lte) {
          queryObject["createdOn"]["$lte"] = new Date(createdOn__lte);
        }
      }
      this.queryForChart = queryObject;
    },
    quoteDailyReportFetchHandler(query: any) {
      const {
        companyNumbers__in,
        createdOn__gte,
        createdOn__lte,
        dateSubmitted__gte,
        dateSubmitted__lte
      } = query;
      const queryObject: any = {};
      if (companyNumbers__in) {
        this.showQuoteChart = "quote-daily-report-single";
        const companyNumberData =
          typeof companyNumbers__in === "number"
            ? [companyNumbers__in]
            : companyNumbers__in.split(",").map((num: string) => parseInt(num));
        queryObject["_id.companyNumber"] = {
          $in: companyNumberData
        };
        queryObject["id_lookup_ratings.companyNumber"] = {
          $in: companyNumberData
        };
      } else {
        this.showQuoteChart = "quote-daily-report-total";
      }
      if (createdOn__gte || createdOn__lte) {
        queryObject["createdOn"] = {};
        if (createdOn__gte) {
          queryObject["createdOn"]["$gte"] = new Date(createdOn__gte);
        }

        if (createdOn__lte) {
          queryObject["createdOn"]["$lte"] = new Date(createdOn__lte);
        }
      }
      if (dateSubmitted__gte || dateSubmitted__lte) {
        queryObject["dateSubmitted"] = {};
        if (dateSubmitted__gte) {
          queryObject["dateSubmitted"]["$gte"] = new Date(dateSubmitted__gte);
        }

        if (dateSubmitted__lte) {
          queryObject["dateSubmitted"]["$lte"] = new Date(dateSubmitted__lte);
        }
      }

      this.queryForChart = queryObject;
    },
    quoteCounterFetchHandler(query: any) {
      const {
        companyNumbers__in,
        createdOn__gte,
        createdOn__lte,
        dateSubmitted__gte,
        dateSubmitted__lte
      } = query;
      const queryObject: any = {};
      if (companyNumbers__in) {
        const companyNumberData =
          typeof companyNumbers__in === "number"
            ? [companyNumbers__in]
            : companyNumbers__in.split(",").map((num: string) => parseInt(num));
        queryObject["_id.companyNumber"] = {
          $in: companyNumberData
        };
      }
      if (createdOn__gte || createdOn__lte) {
        queryObject["createdOn"] = {};
        if (createdOn__gte) {
          queryObject["createdOn"]["$gte"] = new Date(createdOn__gte);
        }

        if (createdOn__lte) {
          queryObject["createdOn"]["$lte"] = new Date(createdOn__lte);
        }
      }
      if (dateSubmitted__gte || dateSubmitted__lte) {
        queryObject["dateSubmitted"] = {};
        if (dateSubmitted__gte) {
          queryObject["dateSubmitted"]["$gte"] = new Date(dateSubmitted__gte);
        }

        if (dateSubmitted__lte) {
          queryObject["dateSubmitted"]["$lte"] = new Date(dateSubmitted__lte);
        }
      }

      this.queryForChart = queryObject;
    },
    quoteChartFetchHandler(query: any) {
      if (this.showQuoteChart === "quote-report-generation") {
        return this.reportGenerationQueryFetch(query);
      } else if (this.showQuoteChart === "quote-counter") {
        return this.quoteCounterFetchHandler(query);
      } else if (
        ["quote-daily-report-total", "quote-daily-report-single"].includes(
          this.showQuoteChart
        )
      ) {
        return this.quoteDailyReportFetchHandler(query);
      } else if (
        [
          "quote-agents",
          "quote-agency",
          "quote-status",
          "quote-county"
        ].includes(this.showQuoteChart)
      ) {
        return this.isLegacyQueryFetch(query);
      }
    }
  },
  computed: {
    ...quoteMapGetters(["getTotalRecords"]),
    ...quoteMapState(["quotes", "links", "makingApiRequest"]),
    ...companiesMapGetters(["companies"]),
    ...authMapGetters(["getCurrentUser"]),
    quoteNotFoundImg() {
      return getSystemFilesURL("quote-home");
    },
    chart(): string {
      const { charts = [] } = this.$store.state.auth["initData"] || {};
      return charts.find((chart: any) => chart.id === this.showQuoteChart);
    },
    userRole(): string {
      return get(this.getCurrentUser, "role", "");
    },
    tableRowActionsData(): any {
      const { deleted, isLegacy } = this.queryOverride;
      let tableRowActions: any = [
        {
          label: "Edit",
          value: "edit",
          faIcon: "edit",
          show: (row: any) =>
            !isLegacy &&
            !deleted &&
            !["submitted", "received", "rejected"].includes(row.status)
        },
        {
          label: "View",
          value: "view",
          faIcon: "eye",
          show: (row: any) =>
            isLegacy ||
            deleted ||
            ["submitted", "received", "rejected"].includes(row.status)
        }
      ];
      if (!this.queryOverride.isLegacy) {
        tableRowActions.push({
          label: "Print",
          value: "print",
          faIcon: "print"
        });
      }
      if (deleted) {
        tableRowActions.push({
          label: "Restore",
          value: "restore",
          faIcon: "trash-restore"
        });
      } else {
        tableRowActions.push({
          label: "Delete",
          value: "delete",
          faIcon: "trash-alt"
        });
      }

      if (
        this.$getCurrentUser &&
        this.$getCurrentUser.role &&
        this.$getCurrentUser.role == "admin" &&
        !this.queryOverride.isLegacy
      ) {
        tableRowActions.push({
          label: " Activities",
          value: "activities",
          faIcon: "book-open"
        });
      }
      return tableRowActions;
    },
    topButtonSecondaryItems(): {
      text: string;
      key: string;
      subItems: { title: string; command: string }[];
    } | null {
      const primary = {
        text: "Actions",
        key: "actions"
      };
      const quotesOptionsObject = {
        title: "Quotes",
        command: "quote"
      };
      const legacyQuotesOptionsObject = {
        title: "Legacy Quotes",
        command: "quotes-legacy"
      };
      const trashQuotesOptionsObject = {
        title: "Trash Bin",
        command: "quotes-deleted"
      };
      const otherUsersOptions = [];

      const ops = [
        {
          title: "Quote Counter",
          command: "quote-counter"
        },
        {
          title: "Quote Report Generation",
          command: "quote-report-generation"
        },
        {
          title: "Quote Property Analytics",
          command: "quote-property-analytics"
        },
        {
          title: "Quotes County Report",
          command: "quote-county"
        },
        {
          title: "Quotes Status Report",
          command: "quote-status"
        },
        {
          title: "Quotes Agent Report",
          command: "quote-agents"
        },
        {
          title: "Quotes Agency Report",
          command: "quote-agency"
        },
        {
          title: "Quotes Daily Report",
          command: "quote-daily-report-total"
        },
        {
          title: "Quotes Ratings Report",
          command: "quote-ratings-report"
        },
        {
          title: "All Activities",
          command: "quote-bulk-activities"
        }
      ];
      const { deleted, isLegacy } = this.queryOverride;
      if (this.showQuoteChart || deleted || isLegacy) {
        otherUsersOptions.push(quotesOptionsObject);
        ops.push(quotesOptionsObject);
      }
      if (!isLegacy) {
        otherUsersOptions.push(legacyQuotesOptionsObject);
        ops.push(legacyQuotesOptionsObject);
      }
      if (!deleted) {
        otherUsersOptions.push(trashQuotesOptionsObject);
        ops.push(trashQuotesOptionsObject);
      }

      if (this.$getCurrentUser?.role === "admin") {
        return {
          ...primary,
          subItems: ops
        };
      }

      if (
        this.$isCurrentUserUnderwriter ||
        this.$isCurrentUserClaims ||
        this.$isCurrentUserAgencyAdmin ||
        this.$isCurrentUserBilling ||
        this.$isCurrentUserDataEntry ||
        this.$getCurrentUser?.role === "agent"
      ) {
        return {
          ...primary,
          subItems: otherUsersOptions
        };
      }

      return null;
    },

    companyOptions(): { label: string; value: number }[] {
      return this.companies
        .filter(
          (company: any) => !excludedCompanies.includes(company.companyNumber)
        )
        .map((company: ICompany) => ({
          label: company.companyName,
          value: company.companyNumber
        }));
    },
    reportGenerationFilters(): any {
      return [
        {
          field: "companyNumbers",
          title: "Company",
          options: this.companyOptions
        },
        {
          field: "policyType",
          title: "Policy Type",
          options: selectOptions.companyLineOfBusiness
        },
        {
          field: "status",
          title: "Status",
          options: [
            { label: "Draft", value: "draft" },
            { label: "Rating Completed", value: "rating-completed" },
            { label: "Not Submitted", value: "not-submitted" },
            { label: "Rating In Progress", value: "rating-in-progress" },
            { label: "Rating Selected", value: "rating-selected" },
            { label: "Received", value: "received" },
            { label: "Rejected", value: "rejected" },
            { label: "Submitted", value: "submitted" }
          ]
        },
        {
          field: "createdOn",
          title: "Created On",
          options: [],
          isDate: true
        },
        {
          field: "dateSubmitted",
          title: "Submitted On",
          options: [],
          isDate: true
        },
        {
          field: "modifiedOn",
          title: "Modified On",
          options: [],
          isDate: true
        },
        {
          field: "isLegacy",
          title: "Legacy",
          options: [
            { label: "Yes", value: "yes" },
            { label: "No", value: "no" }
          ]
        }
      ];
    },
    isLegacyChartFilters(): any {
      return [
        {
          field: "isLegacy",
          title: "Legacy",
          options: [
            { label: "Yes", value: "yes" },
            { label: "No", value: "no" }
          ]
        },
        {
          field: "companyNumbers",
          title: "Company",
          options: this.companyOptions
        },
        {
          field: "createdOn",
          title: "Created On",
          options: [],
          isDate: true
        }
      ];
    },
    quoteCounterChartFilters(): any {
      return [
        {
          field: "companyNumbers",
          title: "Company",
          options: this.companyOptions
        },
        {
          field: "createdOn",
          title: "Created On",
          options: [],
          isDate: true
        },
        {
          field: "dateSubmitted",
          title: "Submitted On",
          options: [],
          isDate: true
        }
      ];
    },
    filters(): any {
      let filters = [
        {
          field: "policyType",
          title: "LOB",
          options: selectOptions.companyLineOfBusiness
        },
        {
          field: "status",
          title: "Status",
          options: [
            { label: "Draft", value: "draft" },
            { label: "Not Submitted", value: "not-submitted" },
            { label: "Rating Completed", value: "rating-completed" },
            { label: "Rating In Progress", value: "rating-in-progress" },
            { label: "Rating Selected", value: "rating-selected" },
            { label: "Received", value: "received" },
            { label: "Rejected", value: "rejected" },
            { label: "Submitted", value: "submitted" }
          ]
        },
        {
          field: "createdOn",
          title: "Created On",
          options: [],
          isDate: true
        },
        {
          field: "dateSubmitted",
          title: "Date Submitted",
          options: [],
          isDate: true
        }
      ];
      if (this.showLegacy) {
        filters.pop();
      }
      if (this.getCurrentUser && !this.showLegacy) {
        filters.push({
          field: "createdBy",
          title: "Created By",
          options: [{ label: "Me", value: this.getCurrentUser._id }]
        });
      }
      return filters;
    },
    bulkMenuActionsData(): any {
      let bulkMenuActions = [{ title: "Print Selected", disabled: false }];
      if (this.getCurrentUser && this.getCurrentUser.role === "admin") {
        const { deleted } = this.queryOverride;
        if (deleted) {
          bulkMenuActions.push({
            title: "Restore Selected",
            disabled: false
          });
        } else {
          bulkMenuActions.push({
            title: "Delete Selected",
            disabled: false
          });
        }
      }
      return bulkMenuActions;
    },
    quoteDailyReportFilter(): any {
      return [
        {
          field: "companyNumbers",
          title: "Company",
          options: this.companyOptions
        },
        {
          field: "createdOn",
          title: "Created On",
          options: [],
          isDate: true
        },
        {
          field: "dateSubmitted",
          title: "Submitted On",
          options: [],
          isDate: true
        }
      ];
    },
    quoteChartFilters(): any {
      if (this.showQuoteChart === "quote-report-generation") {
        return this.reportGenerationFilters;
      } else if (this.showQuoteChart === "quote-counter") {
        return this.quoteCounterChartFilters;
      } else if (
        ["quote-daily-report-total", "quote-daily-report-single"].includes(
          this.showQuoteChart
        )
      ) {
        return this.quoteDailyReportFilter;
      } else if (
        [
          "quote-agents",
          "quote-agency",
          "quote-status",
          "quote-county"
        ].includes(this.showQuoteChart)
      ) {
        return this.isLegacyChartFilters;
      }
      return null;
    },
    chartNeedsListView(): boolean {
      return [
        "quote-report-generation",
        "quote-agents",
        "quote-agency",
        "quote-status",
        "quote-county",
        "quote-counter",
        "quote-daily-report-total",
        "quote-ratings-report",
        "quote-daily-report-single"
      ].includes(this.showQuoteChart);
    }
  },
  mounted() {
    this.$router.replace({ query: {} });
    if (this.$isCurrentUserEzlynx) return;
    let preAction = this.preAction;
    if (this.$route.query && this.$route.query.isLegacy) {
      preAction = "quotes-legacy";
    }

    this.getCompanies({ query: {}, drop_store: true });
    if (preAction) {
      this.onToolbarItemClick(preAction);
    }
  },
  watch: {
    "$getCurrentUser.agentCode": {
      handler() {
        if (this.$getCurrentUser && this.$getCurrentUser.role === "agent") {
          const queryObject = {
            __limit: 50,
            __page: 1,
            __offset: 0
          };
          this.fetchPageData(queryObject);
        }
      },
      deep: true
    }
  }
});
